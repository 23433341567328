import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import DemoLogo from '../../assets/butterreader-logo.svg';
import HomeIcon from '../../assets/icons/home5.svg';
import Design from '../../assets/icons/miror.svg';
import Butter from '../../assets/icons/butter-icon.svg'
import SupportIcon from '../../assets/icons/support.svg'; 
import LogoutIcon from '../../assets/icons/logout.svg'; 
import GeneratorIcon from '../../assets/icons/generator.svg'; 
import { useTranslation } from 'react-i18next';
import { getAuth, signOut } from 'firebase/auth';
import './SideNav.css';

const SideNav = () => {
    const location = useLocation();
    const auth = getAuth();

    const handleLogout = async () => {
        try {
            await signOut(auth);
            // Redirect to login page or handle the user being logged out as needed
        } catch (error) {
            console.error('Logout Error:', error);
            // Handle any errors that occur during logout
        }
    };

    return (
        <div className="side-nav">
            <div className="icon-logo-container">
                <a href="https://www.butterreader.blog" target="_blank" rel="noopener noreferrer" className="logo-link">
                    <img src={DemoLogo} alt='demo' className="icon-logo" />
                </a>
            </div>
            {/* Navigation Links */}
            <div className="nav-links">
                <Link to="/" className={`nav-link ${location.pathname === "/" ? "selected" : ""}`}>
                    <img src={GeneratorIcon} alt='generator' className="nav-icon" />
                    <span>Generator</span>
                </Link>
                <Link to="/widget" className={`nav-link ${location.pathname === "/widget" ? "selected" : ""}`}>
                    <img src={HomeIcon} alt='demo' className="nav-icon" />
                    <span>Home</span>
                </Link>
                <Link to="/blog-widget" className={`nav-link ${location.pathname === "/blog-widget" ? "selected" : ""}`}>
                    <img src={Design} alt='demo' className="nav-icon" />
                    <span>Design</span>
                </Link>
                <Link to="/installation" className={`nav-link ${location.pathname === "/installation" ? "selected" : ""}`}>
                    <img src={Butter} alt='demo' className="nav-icon" />
                    <span>Install</span>
                </Link>
            </div>
            <div className="support-logout">
                <Link to="/settings" className="nav-link">
                    <img src={SupportIcon} alt='demo' className="nav-icon" />
                    <span>Settings</span>
                </Link>
                <Link className="nav-link" onClick={handleLogout}>
                    <img src={LogoutIcon} alt='demo' className="nav-icon" />
                    <span>Logout</span>
                </Link>
            </div>ß
        </div>
    );
}

export default SideNav;
