import { createContext, useContext, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import AuthPage from './pages/AuthPage';
import HomePage from './pages/HomePage';
import Dashboard from './components/Dashboard';
import Playground from './pages/Playground';
import Install from './pages/InstallationGuidePage'
import Generator from './pages/Generator'
import DemoPage from './components/website/DemoPage'
import Setting from './pages/Settings'
import { AudioPlayerProvider } from './components/AudioPlayerContext';
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { I18nextProvider } from 'react-i18next';
import { getUserPlanAndUsage } from './components/planService'
import i18n from './i18n';
import UpgradePlanPrompt from './components/UpgradePlanPrompt';

export const UserPlanContext = createContext(null);
export const usePlanDetails = () => useContext(UserPlanContext);

function App() {
  const [user, setUser] = useState(null);
  const [userPlan, setUserPlan] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        const planDetails = await getUserPlanAndUsage();
        setUserPlan(planDetails.planName);
      }
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const ProtectedRoute = ({ element, requiredPlan }) => {
    if (loading) return <div>Loading...</div>;
    if (!user) return <Navigate to="/login" />;
    if (userPlan === 'God Level' || userPlan === requiredPlan) return element;
    return <UpgradePlanPrompt currentPlan={userPlan} requiredPlan={requiredPlan} />;
  };

  const ProtectedLayout = ({ user, loading }) => {
    if (loading) return <div>Loading...</div>;
    if (!user) return <Navigate to="/login" />;
    return <Dashboard />;
  };

  return (
    <AudioPlayerProvider>
      <UserPlanContext.Provider value={userPlan}>
        <I18nextProvider i18n={i18n}>
          <Router>
            <Routes>
              <Route path="/login" element={user ? <Navigate to="/" /> : <AuthPage />} />
              <Route path="/demo" element={<DemoPage />} />
              <Route path="/" element={<ProtectedLayout user={user} loading={loading} />}>
                <Route index element={<Generator />} />
                <Route path="blog-widget" element={<ProtectedRoute element={<Playground />} requiredPlan="Pro" />} />
                <Route path="installation" element={<ProtectedRoute element={<Install />} requiredPlan="Pro" />} />
                <Route path="widget" element={<ProtectedRoute element={<HomePage />} requiredPlan="Pro" />} />
                <Route path="settings" element={<Setting />} />
              </Route>
            </Routes>
          </Router>
        </I18nextProvider>
      </UserPlanContext.Provider>
    </AudioPlayerProvider>
  );
}

export const useUserPlan = () => useContext(UserPlanContext);
export default App;
