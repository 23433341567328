import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PlayIcon from '../../assets/icons/play-circle.svg';
import './Stats.css';
import { firestore, auth } from '../../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';

const AvgDuration = () => {
    const { t } = useTranslation();
    const [avgDuration, setAvgDuration] = useState(0);
    const [percentageChange, setPercentageChange] = useState(0);

    useEffect(() => {
        const fetchAverageDuration = async () => {
            const userId = auth.currentUser.uid;
            const durationsRef = collection(firestore, 'playEvents');

            const now = new Date();
            const startOfThisWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay(), 0, 0, 0);
            const startOfLastWeek = new Date(startOfThisWeek.getFullYear(), startOfThisWeek.getMonth(), startOfThisWeek.getDate() - 7, 0, 0, 0);
            const endOfLastWeek = new Date(startOfThisWeek);

            const thisWeekQuery = query(durationsRef, where('userId', '==', userId), where('timestamp', '>=', startOfThisWeek.toISOString()));
            const lastWeekQuery = query(durationsRef, where('userId', '==', userId), where('timestamp', '>=', startOfLastWeek.toISOString()), where('timestamp', '<', endOfLastWeek.toISOString()));

            const [thisWeekSnapshot, lastWeekSnapshot] = await Promise.all([getDocs(thisWeekQuery), getDocs(lastWeekQuery)]);

            let thisWeekTotalDuration = 0, thisWeekCount = 0;
            let lastWeekTotalDuration = 0, lastWeekCount = 0;

            thisWeekSnapshot.forEach(doc => {
                const duration = Number(doc.data().duration);
                if (duration > 0) {
                    thisWeekTotalDuration += duration;
                    thisWeekCount++;
                }
            });

            lastWeekSnapshot.forEach(doc => {
                const duration = Number(doc.data().duration);
                if (duration > 0) {
                    lastWeekTotalDuration += duration;
                    lastWeekCount++;
                }
            });

            const thisWeekAvg = thisWeekCount > 0 ? thisWeekTotalDuration / thisWeekCount : 0;
            const lastWeekAvg = lastWeekCount > 0 ? lastWeekTotalDuration / lastWeekCount : 0;

            setAvgDuration(thisWeekAvg);
            const change = thisWeekAvg - lastWeekAvg;
            setPercentageChange(lastWeekAvg === 0 ? 0 : (change / lastWeekAvg) * 100);
        };

        fetchAverageDuration();
    }, []);

    const formatDuration = (duration) => {
        const minutes = Math.floor(duration / 60);
        const seconds = Math.floor(duration % 60);
        return `${minutes}m ${seconds}s`;
    };

    return (
        <div className="total-plays-black">
            <div className="stat-info">
                <img src={PlayIcon} alt="Play Icon" className="icon" />
                <h3 className="head-number">{formatDuration(avgDuration)}</h3>
                <div className="stacked-text">
                    <p className='label'>{t('avg duration')}</p>
                    {/*<p className='change'>{percentageChange >= 0 ? `+${percentageChange.toFixed(2)}%` : `${percentageChange.toFixed(2)}%`} this week</p>*/}
                </div>
            </div>
        </div>
    );
}

export default AvgDuration;
