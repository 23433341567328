import { createContext, useState } from 'react';

export const AudioPlayerContext = createContext({});

export const AudioPlayerProvider = ({ children }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [customStyles, setCustomStyles] = useState({
    audioPlayer: { background: '#282828' },
    playPauseButton: { color: '#FFD600', background: '#282828' },
    progressBar: { backgroundColor: '#FFD600' },
    theme: { backgroundColor: '#FFD600' },
    time: { color: 'white' }
  });
  const [voiceSettings, setVoiceSettings] = useState({
    name: 'Alloy', 
    audioSrc: null,
    model: 'tts-1',
    speed: 1.0
  });

  const updateSettings = (newCustomStyles, newVoiceSettings) => {
    setCustomStyles(prevStyles => ({ ...prevStyles, ...newCustomStyles }));
    setVoiceSettings(prevSettings => ({ ...prevSettings, ...newVoiceSettings }));
  };

  return (
    <AudioPlayerContext.Provider value={{ 
      customStyles, 
      setCustomStyles, 
      isPlaying, 
      setIsPlaying, 
      voiceSettings, 
      setVoiceSettings,
      updateSettings 
    }}>
      {children}
    </AudioPlayerContext.Provider>
  );
};
