import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, addDoc, doc, getDoc, setDoc, deleteDoc, updateDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import Header from './Header';
import StandaloneAudioPlayer from './StandaloneAudioPlayer';
import DesignComponent from './DesignComponent';
import { serverTimestamp } from 'firebase/firestore';
import './DemoPage.css';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import moneyIcon from '../../assets/icons/knife.svg';

const DemoPage = () => {
  const navigate = useNavigate();
  const [text, setText] = useState('');
  const [embedCode, setEmbedCode] = useState('');
  const [audioGenerated, setAudioGenerated] = useState(false);
  const [audioKey, setAudioKey] = useState(0);
  const [audioUrl, setAudioUrl] = useState('');
  const [selectedVoice, setSelectedVoice] = useState('Alloy');
  const [paymentRequired, setPaymentRequired] = useState(false);
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);
  const [docId, setDocId] = useState('');
  const [stripePriceId, setStripePriceId] = useState('');
  const [charLimit, setCharLimit] = useState(500);
  const [uploadedAudio, setUploadedAudio] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [customStyles, setCustomStyles] = useState({
    container: {},
    player: {},
    showCompanyTag: true,
  });
  const [designComponentState, setDesignComponentState] = useState({
    themeColor: '#FF6900',
    isDarkMode: true,
    showCompanyTag: true,
    selectedVoice: 'Alloy',
    isHDVoice: false,
    voiceSpeed: 1.0
  });
  const [price, setPrice] = useState(0);

  const defaultStyles = {
    container: {},
    player: {},
    showCompanyTag: true,
  };

  const handleTextChange = (e) => {
    const newText = e.target.value;
    if (newText.length <= charLimit) {
      setText(newText);
      if (newText.trim().length === 0) {
        setUploadedAudio(null);
      }
      resetAudioGenerationState();
    }
  };

  const handleFileUpload = (file) => {
    if (file && file.type.startsWith('audio/') && file.size <= 5 * 1024 * 1024) {
      setUploadedAudio(file);
      setText('');
    } else {
      alert('Please upload an audio file smaller than 5MB.');
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    handleFileUpload(file);
  };

  const handleGenerateAudio = async () => {
    if (!text.trim() && !uploadedAudio) {
      alert("Please enter some text or upload an audio file before generating.");
      return;
    }
    try {
      let audioUrl;
      let priceId = null;

      if (uploadedAudio || designComponentState.isHDVoice) {
        priceId = 'price_1Q6EzfCEY8Ej5Z6H3kHjDyqL';
        setPrice(0.50);
      } else if (!customStyles.showCompanyTag) {
        priceId = 'price_1OwoqMCEY8Ej5Z6HlVy2EwSv';
        setPrice(1.47);
      } else {
        setPrice(0);
      }

      if (uploadedAudio) {
        audioUrl = await uploadAudioFile(uploadedAudio);
      } else {
        const response = await fetch('https://us-central1-butter-reader.cloudfunctions.net/synthesizeDemoSpeech', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            text,
            voice: selectedVoice.toLowerCase(),
            speed: designComponentState.voiceSpeed,
            isHDVoice: designComponentState.isHDVoice
          })
        });

        if (response.ok) {
          const data = await response.json();
          audioUrl = data.url;
        } else {
          throw new Error("Failed to generate audio");
        }
      }

      setAudioUrl(audioUrl);
      setAudioKey(prevKey => prevKey + 1);
      setAudioGenerated(true);
      setPaymentRequired(!!priceId);
      setStripePriceId(priceId);

      // Save to demo collection
      const docRef = await addDoc(collection(firestore, "demo"), {
        audioUrl,
        customStyles,
        showCompanyTag: customStyles.showCompanyTag,
        createdAt: serverTimestamp(),
        text,
        voice: selectedVoice,
        paymentRequired: !!priceId,
        stripePriceId: priceId,
        isHDVoice: designComponentState.isHDVoice,
        voiceSpeed: designComponentState.voiceSpeed,
        isUploadedAudio: !!uploadedAudio,
        price: price
      });

      setDocId(docRef.id);
    } catch (error) {
      console.error('Error generating audio:', error);
      alert('An error occurred while generating the audio. Please try again.');
    }
  };

  const handlePaymentRequirement = (requiresPayment, priceId, price) => {
    setPaymentRequired(requiresPayment);
    setStripePriceId(priceId);
    setPrice(price);
  };

  const handleSignificantChange = () => {
    setAudioGenerated(false);
    setEmbedCode('');
  };

  const generateEmbedCode = (audioSrc, docId) => {
    const newEmbedCode = `<div id="demo-widget-container" data-config-id="${docId}"></div>
<script src="https://app.butterreader.blog//widget/DemoWidget.bundle.js"></script>`;
    setEmbedCode(newEmbedCode);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paymentSuccess = params.get('paymentSuccess');
    const docIdFromQuery = params.get('docId');

    if (paymentSuccess === 'true' && docIdFromQuery) {
      setPaymentSuccessful(true);
      fetchDocumentAndGenerateEmbedCode(docIdFromQuery);
    }
  }, []);

  const fetchDocumentAndGenerateEmbedCode = async (docId) => {
    const docRef = doc(firestore, 'demo', docId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      if (data.paid) {
        setAudioUrl(data.audioUrl);
        setCustomStyles(data.customStyles || defaultStyles);
        generateEmbedCode(data.audioUrl, docId);
      } else {
        console.error("This audio is not paid for and cannot be accessed.");
        await deleteDoc(docRef);
      }
    } else {
      console.error("Document not found");
    }
  };

  const handleDesignChange = (newStyles) => {
    resetAudioGenerationState();
    setCustomStyles(prevStyles => {
      const updatedStyles = { ...prevStyles, ...newStyles };
      setDesignComponentState(prevState => ({
        ...prevState,
        themeColor: updatedStyles.theme?.backgroundColor || prevState.themeColor,
        isDarkMode: updatedStyles.isDarkMode !== undefined ? updatedStyles.isDarkMode : prevState.isDarkMode,
        showCompanyTag: updatedStyles.showCompanyTag !== undefined ? updatedStyles.showCompanyTag : prevState.showCompanyTag,
        isHDVoice: updatedStyles.isHDVoice !== undefined ? updatedStyles.isHDVoice : prevState.isHDVoice,
        voiceSpeed: updatedStyles.voiceSpeed || prevState.voiceSpeed
      }));
      return updatedStyles;
    });
  };

  const handleGetEmbedCode = () => {
    const stateToSave = {
      text,
      audioUrl,
      customStyles,
      selectedVoice,
      paymentRequired,
      stripePriceId,
      audioGenerated,
      embedCode,
      docId,
      uploadedAudio: uploadedAudio ? {
        name: uploadedAudio.name,
        type: uploadedAudio.type,
        size: uploadedAudio.size,
        lastModified: uploadedAudio.lastModified
      } : null,
      designComponentState,
      isHDVoice: designComponentState.isHDVoice,
      voiceSpeed: designComponentState.voiceSpeed
    };
    localStorage.setItem('demoState', JSON.stringify(stateToSave));
    navigate(`/login?docId=${docId}`);
  };

  const uploadAudioFile = async (file) => {
    const storage = getStorage();
    const fileName = `demoAudio/${Date.now()}_${file.name}`;
    const storageRef = ref(storage, fileName);
    
    try {
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      return downloadURL;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw new Error('Failed to upload audio file. Please try again.');
    }
  };

  const handleVoiceChange = (newVoice) => {
    setSelectedVoice(newVoice);
    setDesignComponentState(prevState => ({
      ...prevState,
      selectedVoice: newVoice
    }));
  };

  const resetAudioGenerationState = () => {
    setAudioGenerated(false);
    setEmbedCode('');
    setDocId('');
    setPaymentRequired(false);
    setStripePriceId('');
    setPrice(0);
  };

  return (
    <div className="demo-full-page">
      <Header />
      <div className="demo-page">
        <div className="left-panel">
          <h1>Create {price === 0 ? 'Free' : `$${price.toFixed(2)}`} Audio Widget</h1>
          <p>Transform your content into engaging audio experiences. Choose between text-to-speech conversion or upload your own audio file.</p>
          
          <div className="input-options">
            {!uploadedAudio && (
              <div className="text-input-area">
                <h3>Option 1: Text-to-Speech</h3>
                <p>Enter your text below to convert it to speech (up to {charLimit} characters):</p>
                <div className="textarea-container">
                  <textarea 
                    value={text} 
                    onChange={handleTextChange} 
                    maxLength={charLimit} 
                    placeholder="Enter text here..." 
                  />
                  <div className="char-count">
                    {text.length}/{charLimit}
                  </div>
                </div>
              </div>
            )}
            
            {!text.trim() && (
              <div className="audio-upload-area">
                <h3>Option 2: Upload Your Audio</h3>
                <p>Drag and drop your audio file here (max 5MB):</p>
                <div 
                  className={`drop-zone ${isDragging ? 'dragging' : ''} ${uploadedAudio ? 'has-file' : ''}`}
                  onDragEnter={handleDragEnter}
                  onDragOver={(e) => e.preventDefault()}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                  onClick={() => document.getElementById('file-input').click()}
                >
                  {uploadedAudio ? (
                    <p>File uploaded: {uploadedAudio.name}</p>
                  ) : (
                    <p>Drag your audio file here or click to upload</p>
                  )}
                  <input 
                    id="file-input"
                    type="file" 
                    accept="audio/*" 
                    onChange={(e) => handleFileUpload(e.target.files[0])} 
                    style={{display: 'none'}}
                  />
                </div>
                {uploadedAudio && (
                  <button className="remove-audio-button" onClick={() => setUploadedAudio(null)}>Remove Audio</button>
                )}
              </div>
            )}
          </div>
          
          {(text.trim().length > 0 || uploadedAudio) && !audioGenerated && (
            <button className='generate-button' onClick={handleGenerateAudio} disabled={!selectedVoice && !uploadedAudio}>
              Generate Audio Widget
            </button>
          )}
          
          {audioGenerated && (
            <div className="embed-code-div">
              <h3>Your Custom Widget is Ready!</h3>
              <p>Your audio has been generated successfully. To get your embed code and add this interactive audio player to your website, you need to log in or sign up.</p>
              <button className='login-button' onClick={handleGetEmbedCode}>Log in / Sign up to Get Embed Code</button>
            </div>
          )}
        </div>
        <div className="right-side-demo">
          <StandaloneAudioPlayer key={audioKey} audioSrc={audioUrl} customStyles={customStyles} showCompanyTag={customStyles.showCompanyTag} />
          <DesignComponent
            onDesignChange={handleDesignChange}
            onVoiceChange={handleVoiceChange}
            onPaymentRequirement={handlePaymentRequirement}
            onSignificantChange={handleSignificantChange}
            initialState={designComponentState}
            customStyles={customStyles}
            moneyIcon={moneyIcon}
            uploadedAudio={uploadedAudio}
          />          
        </div>
      </div>
    </div>
  );
};

export default DemoPage;
