import React, { useContext, useState, useEffect } from 'react';
import TopBar from '../components/playground/TopBar';
import Design from '../components/design/Design';
import PreviewPage from '../components/design/PreviewPage';
import ControlPanel from '../components/playground/ControlPanel';
import { AudioPlayerContext } from '../components/AudioPlayerContext';
import { auth, firestore } from '../firebase';
import { doc, setDoc, getDoc, collection } from "firebase/firestore";
import { getUserPlanAndUsage } from '../components/planService'; 
import './Playground.css';

function Playground() {
  const { customStyles, voiceSettings, updateSettings } = useContext(AudioPlayerContext);
  const [canEdit, setCanEdit] = useState(true);
  const [audioSrc, setAudioSrc] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  

  useEffect(() => {
    fetchUserSettingsAndPlan();
  }, []);

  const fetchUserSettingsAndPlan = async () => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId) {
        console.log('No user logged in');
        return;
      }

      const userDocRef = doc(firestore, 'customers', userId);
      const stylesCollectionRef = collection(userDocRef, 'styles');
      const stylesDocRef = doc(stylesCollectionRef, 'current');
      
      try {
        const docSnap = await getDoc(stylesDocRef);

        if (docSnap.exists()) {
          const savedSettings = docSnap.data();
          console.log('Retrieved from Firestore:', savedSettings);
          updateSettings(savedSettings.customStyles || {}, savedSettings.voiceSettings || {});
        } else {
          console.log('No custom styles found, using default styles');
          const defaultStyles = {
            audioPlayer: { background: '#282828' },
            playPauseButton: { color: '#FFD600', background: '#282828' },
            time: { color: 'white' },
            progressContainer: { background: '#ffffff29' },
            showCompanyTag: true
          };
          const defaultVoiceSettings = {
            name: 'Alloy',
            model: 'tts-1',
            speed: 1.0
          };
          updateSettings(defaultStyles, defaultVoiceSettings);
        }
      } catch (error) {
        console.error("Error fetching styles:", error);
        // Use default styles here as well
        const defaultStyles = {
          audioPlayer: { background: '#282828' },
          playPauseButton: { color: '#FFD600', background: '#282828' },
          time: { color: 'white' },
          progressContainer: { background: '#ffffff29' },
          showCompanyTag: true
        };
        const defaultVoiceSettings = {
          name: 'Alloy',
          model: 'tts-1',
          speed: 1.0
        };
        updateSettings(defaultStyles, defaultVoiceSettings);
      }

      const planData = await getUserPlanAndUsage();
      if (planData) {
        setCanEdit(planData.planName.toLowerCase() !== 'free');
      }
    } catch (error) {
      console.error("Error fetching user settings and plan:", error);
    }
  };

  const handleSaveDesign = async (design) => {
    const userId = auth.currentUser.uid;
    const userDocRef = doc(firestore, 'customers', userId);
    const stylesCollectionRef = collection(userDocRef, 'styles');
    const stylesDocRef = doc(stylesCollectionRef, 'current');
    const dataToSave = { 
      customStyles: { 
        ...customStyles, 
        ...design.customStyles,
        audioPlayer: { ...design.customStyles.audioPlayer },
      },
      voiceSettings: { ...voiceSettings, ...design.voiceSettings }
    };
    console.log('Saving to Firestore:', dataToSave);
    await setDoc(stylesDocRef, dataToSave);
    updateSettings(design.customStyles, design.voiceSettings);
  };

  const handleTestAudio = async (testAudioUrl) => {
    setAudioSrc(testAudioUrl);
  };

  const blogContent = `Very Happy Meal

What's going here?
McDonald's reported golden third-quarter results on Monday, and they may be worth some lovin'.

What does this mean?
Investors have been keeping McDonald's at arm's length, wary that the US's new obsession with weight-loss drugs won't exactly jibe with Happy Meals. But it seems the fast food giant kept its "Supersize Me" spirit: by beefing up prices, McDonald's managed to make 9% more in same-store sales.`;

  return (
    <div className="Custom">
      <TopBar />
      <div className="playground">
        <div className="left-panel">
          <PreviewPage 
            audioSrc={audioSrc || voiceSettings.audioSrc}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
          />
          <Design 
            onSaveDesign={handleSaveDesign} 
            onTestAudio={handleTestAudio}
            canEdit={canEdit} 
            customStyles={customStyles}
            voiceSettings={voiceSettings}
            hasChanges={hasChanges}
            setHasChanges={setHasChanges}
            blogContent={blogContent}
          />
        </div>
        <div className="right-panel">
          <ControlPanel 
            blogContent={blogContent}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            audioSrc={audioSrc || voiceSettings.audioSrc}
          />
        </div>
      </div>
    </div>
  );
}

export default Playground;
