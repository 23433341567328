import React, { useState, useEffect } from 'react';
import PlayIcon from '../../assets/icons/sound.svg';
import './Stats.css';
import { auth, firestore } from '../../firebase';
import { collection, query, where, getDocs, Timestamp } from 'firebase/firestore';

const TotalPlays = () => {
    const [totalPlays, setTotalPlays] = useState(0);
    const [percentageChange, setPercentageChange] = useState(0);

    useEffect(() => {
        const fetchTotalPlays = async () => {
            if (!auth.currentUser) return;
            const userId = auth.currentUser.uid;

            const now = new Date();
            const startOfThisWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay(), 0, 0, 0);
            const startOfLastWeek = new Date(new Date(startOfThisWeek).setDate(startOfThisWeek.getDate() - 7));

            const thisWeekQuery = query(collection(firestore, 'playEvents'), 
                where('userId', '==', userId), 
                where('timestamp', '>=', startOfThisWeek.toISOString())
            );
            const lastWeekQuery = query(collection(firestore, 'playEvents'), 
                where('userId', '==', userId), 
                where('timestamp', '>=', startOfLastWeek.toISOString()), 
                where('timestamp', '<', startOfThisWeek.toISOString())
            );


            const [thisWeekSnapshot, lastWeekSnapshot] = await Promise.all([getDocs(thisWeekQuery), getDocs(lastWeekQuery)]);

            let thisWeekPlays = thisWeekSnapshot.docs.length;
            let lastWeekPlays = lastWeekSnapshot.docs.length;

            setTotalPlays(thisWeekPlays);
            const change = thisWeekPlays - lastWeekPlays;
            setPercentageChange(lastWeekPlays === 0 ? 0 : Math.round((change / lastWeekPlays) * 100));
        };

        fetchTotalPlays();
    }, []);

    return (
        <div className="total-plays">
            <div className="stat-info">
                <img src={PlayIcon} alt="Play Icon" className="icon" />
                <h3 className="head-number">{totalPlays.toLocaleString()}</h3>
                <div className="stacked-text">
                    <p className='label'>total plays</p>
                    {/* <p className='change'>{percentageChange >= 0 ? `+${percentageChange}%` : `${percentageChange}%`} this week</p> */}
                </div>
            </div>
        </div>
    );
};

export default TotalPlays;
