import React, { useRef, useState, useEffect } from 'react';
import logoSvg from '../../assets/butterreader-logo.svg'; // Ensure the correct path
import '../AudioPlayer.css';

function StandaloneAudioPlayer({ audioSrc, customStyles, showCompanyTag }) {
    const audioRef = useRef(null);
    const [playStartTime, setPlayStartTime] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    

    useEffect(() => {
        const audio = audioRef.current;
        const handleLoadedMetadata = () => setDuration(audio.duration);
        const handleTimeUpdate = () => setCurrentTime(audio.currentTime);

        audio.addEventListener('loadedmetadata', handleLoadedMetadata);
        audio.addEventListener('timeupdate', handleTimeUpdate);
        const audioPlayerElement = audioRef.current.parentElement;
        if (audioPlayerElement && customStyles.audioPlayer) {
            Object.assign(audioPlayerElement.style, customStyles.audioPlayer);
        }

        return () => {
            audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
            audio.removeEventListener('timeupdate', handleTimeUpdate);
        };
    }, [customStyles]);

    useEffect(() => {
        const handleUnload = () => {
            if (isPlaying && playStartTime) {
                const endTime = new Date().getTime();
                const sessionDuration = (endTime - playStartTime) / 1000; // Duration in seconds
            }
        };

        window.addEventListener('beforeunload', handleUnload);

        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, [isPlaying, playStartTime, audioSrc]);

    const togglePlayPause = () => {
        if (!audioSrc) {
            alert("No audio has been generated yet. Please generate audio first.");
            return; // Prevent further execution if there's no audio source
        }
    
        setIsPlaying(!isPlaying);
        if (!isPlaying) {
            audioRef.current.play();
            setPlayStartTime(new Date().getTime()); // Update play start time
        } else {
            audioRef.current.pause();
        }
    };    

    const handlePlay = () => {
        setIsPlaying(true);
        setPlayStartTime(new Date().getTime()); // Set the play start time when audio starts playing
    };    

    const handlePause = () => {
        setIsPlaying(false);
    };

    const handleEnded = () => {
        setIsPlaying(false);
    };

    useEffect(() => {
        const audio = audioRef.current;
        audio.addEventListener('play', handlePlay);
        audio.addEventListener('pause', handlePause);
        audio.addEventListener('ended', handleEnded);

        return () => {
            audio.removeEventListener('play', handlePlay);
            audio.removeEventListener('pause', handlePause);
            audio.removeEventListener('ended', handleEnded);
        };
    }, []);

    const handleProgressClick = (e) => {
        const boundingRect = e.target.getBoundingClientRect();
        const clickedPosition = e.clientX - boundingRect.left;
        const newTime = (clickedPosition / boundingRect.width) * duration;
        audioRef.current.currentTime = newTime;
    };

    const formatTime = (time) => {
        const floorTime = Math.floor(time);
        const minutes = Math.floor(floorTime / 60);
        const seconds = floorTime % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return (
        <div className="widget-outline">
            <div className="audio-player" style={customStyles.audioPlayer}>
                <audio ref={audioRef} src={audioSrc} preload="metadata" />
                <div className="audio-player__theme" style={customStyles.theme}>
                    <button 
                        className="audio-player__play-pause-btn" 
                        onClick={togglePlayPause} 
                        style={customStyles.playPauseButton}>
                        {isPlaying ? '||' : '►'}
                    </button>
                </div>
                <div 
                    className="audio-player__progress-container" 
                    onClick={handleProgressClick} 
                    style={customStyles.progressContainer}>
                    <div 
                        className="audio-player__progress-bar" 
                        style={{ ...customStyles.progressBar, width: `${(currentTime / duration) * 100}%` }} />
                </div>
                <div className="audio-player__time" style={customStyles.time}>
                    {formatTime(currentTime)}
                </div>
                </div>
            {showCompanyTag && (
                <div className="powered-by-logo">
                    <a href="https://www.butterreader.blog/" target="_blank" rel="noopener noreferrer">
                        Powered by <img src={logoSvg} alt="Logo" />
                    </a>
                </div>
            )}
        </div>
    );
}

export default StandaloneAudioPlayer;
