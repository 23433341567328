import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import SideNav from './navigation/SideNav';
import DashboardHeader from './navigation/DashboardHeader';
import PriceTableModal from './priceTable';
import UpgradePlanPrompt from './UpgradePlanPrompt';
import { useUserPlan } from '../App';
import './Dashboard.css';

const Dashboard = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const userPlan = useUserPlan();

    useEffect(() => {
        if (location.pathname === '/') {
            navigate('/');
        } else {
            localStorage.setItem('lastPath', location.pathname);
        }
    }, [location.pathname, navigate]);

    let title;
    switch (location.pathname) {
        case '/':
            title = 'Generator';
            break;
        case '/blog-widget':
            title = 'Blog Widget';
            break;
        case '/installation':
            title = 'Install';
            break;
        case '/settings':
            title = 'Settings';
            break;
        case '/widget':
            title = 'Blog Widget';
            break;
        default:
            title = 'Dashboard';
    }

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const isRestrictedPage = location.pathname !== '/' && location.pathname !== '/settings';
    const requiredPlan = isRestrictedPage ? 'Pro' : 'Free';

    return (
        <div className="dashboard">
            <SideNav />
            <div className="dashboard-content">
                <DashboardHeader title={title} onUpgradeClick={handleOpenModal} />
                {isRestrictedPage && userPlan !== 'God Level' && userPlan !== requiredPlan ? (
                    <UpgradePlanPrompt currentPlan={userPlan} requiredPlan={requiredPlan} />
                ) : (
                    <Outlet />
                )}
                <PriceTableModal isOpen={isModalOpen} onClose={handleCloseModal} />
            </div>
        </div>
    );
};

export default Dashboard;
