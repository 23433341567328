import React, { useState, useRef, useEffect, useContext } from 'react';
import './Stats.css'; // Ensure this path is correct
import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg';
import { auth } from '../../firebase'; // Adjust the import path as necessary

const Embed = () => {
    const [copied, setCopied] = useState(false);
    const textRef = useRef(null);
    const [embedCode, setEmbedCode] = useState('');

    useEffect(() => {
        if (auth.currentUser) {
            const userId = auth.currentUser.uid;
            const scriptTag = `<div id="audio-player-widget-container"></div>
<script src="https://app.butterreader.blog/widget/AudioPlayerWidget.bundle.js"></script>
<script>
    window.initAudioPlayerWidget('audio-player-widget-container', '${userId}');
</script>
`;
            setEmbedCode(scriptTag);
        }
    }, []);

    const handleCopy = () => {
        navigator.clipboard.writeText(embedCode).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
        });
    };

    return (
        <div className="embed-container">
            <h3 className='embed-label'>Embed your widget</h3>
            <p className='embed-body'>Copy the code and place it just between your blog image and content</p>
            <div className='embed-copy-box' onClick={handleCopy}>
                <pre className="embed-box" ref={textRef}>{embedCode}</pre>
            </div>
            {copied && <div className="copy-notification">Copied!</div>}
        </div>
    );
};

export default Embed;
