import React, { useState, useEffect } from 'react';
import TotalPlays from '../components/dashboard/TotalPlays';
import Embed from '../components/dashboard/Embed';
import CurrentPlan from '../components/dashboard/CurrentPlan';
import Banner from '../components/dashboard/Banner';
import AvgDuration from '../components/dashboard/AvgDuration';
import Leaderboard from '../components/dashboard/Leaderboards';
import PriceTableModal from '../components/priceTable';
import SuccessPopup from '../components/SuccessPopup';
import { getUserPlanAndUsage } from '../components/planService';
import { auth, firestore } from "../firebase"; 
import { onSnapshot, collection } from 'firebase/firestore'; 
import './HomePage.css';

const HomePage = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userPlan, setUserPlan] = useState('free');
    const [showSuccessPopup, setShowSuccessPopup] = useState(false); // State to control SuccessPopup visibility

    useEffect(() => {
        const fetchPlan = async () => {
            try {
                const planDetails = await getUserPlanAndUsage();
                setUserPlan(planDetails.planName);
            } catch (error) {
                console.error("Error fetching plan:", error);
            }
        };
        
        fetchPlan();
    }, []);    

    useEffect(() => {
        const userId = auth.currentUser?.uid;
        if (userId) {
          const unsubscribe = onSnapshot(
            collection(firestore, "customers", userId, "subscriptions"),
            (snapshot) => {
              const subscriptions = snapshot.docs.map(doc => doc.data());
              const activeSubscription = subscriptions.find(sub => sub.status === "active");
              const paymentInitiated = localStorage.getItem('paymentInitiated');
              if (activeSubscription && paymentInitiated) {
                handlePaymentSuccess();
                localStorage.removeItem('paymentInitiated'); // Remove flag here
              }
            }
          );
          return () => unsubscribe();
        }
      }, []);       

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    // Function to handle successful payment
    const handlePaymentSuccess = () => {
        setShowSuccessPopup(true); // Show success pop-up
    };

    // Function to close success pop-up
    const handleCloseSuccessPopup = () => {
        setShowSuccessPopup(false); // Close success pop-up
    };

    return (
        <div className="home-container">
            <div className="grid-container">
                <div className="grid-item stats-top-left">
                    <TotalPlays />
                    <AvgDuration />
                </div>
                <div className="grid-item comments-section">
                    <Leaderboard />
                </div>
                <div className="grid-item banner">
                    <Banner/>
                </div>
                <div className="grid-item widget-info">
                    <Embed />
                </div>
                <div className="grid-item progress-circle">
                    <PriceTableModal isOpen={isModalOpen} onClose={handleCloseModal} userPlan={userPlan} onPaymentSuccess={handlePaymentSuccess} />
                    <CurrentPlan onUpgradeClick={handleOpenModal} />
                </div>
                <PriceTableModal 
                    isOpen={isModalOpen} 
                    onClose={handleCloseModal} 
                    userPlan={userPlan} 
                    onPaymentSuccess={handlePaymentSuccess}
                />
                <SuccessPopup 
                    isOpen={showSuccessPopup} 
                    onClose={handleCloseSuccessPopup} 
                    message="Your payment was successful!"
                />
            </div>
        </div>
    );
}

export default HomePage;
