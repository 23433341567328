import React from 'react';
import { useTranslation } from 'react-i18next';
import './DashboardHeader.css';
import HelpIcon from '../../assets/icons/help.svg';

const DashboardHeader = ({ title, onUpgradeClick }) => {
  const { t } = useTranslation();

  return (
    <div className="dashboard-header">
      <div className="dashboard-title">
        <h2>{title}</h2>
      </div>
      <div className="dashboard-actions">
        <a href="https://www.butterreader.blog/how-it-works" target="_blank" rel="noopener noreferrer">
            <img src={HelpIcon} alt={t('helpIcon')} className="help-icon" />
        </a>
        <button onClick={onUpgradeClick} className="upgrade-btn">Upgrade</button>
    </div>
  </div>
);

}

export default DashboardHeader;
