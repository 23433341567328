import React, { useContext } from 'react';
import happyMealImage from '../../../assets/mcdonalds.jpg';
import './LiveDemo.css';
import PreviewPage from '../../design/PreviewPage';
import { AudioPlayerContext } from '../../AudioPlayerContext';

const LiveDemo = ({ 
  blogContent, 
  isPlaying, 
  setIsPlaying,
  audioSrc
}) => {
  const { customStyles, voiceSettings } = useContext(AudioPlayerContext);

  return (
    <div className="blog-post">
      <img src={happyMealImage} alt="Happy Meal" className="blog-post-image" />
      <div className="blog-post-content">
        <h1 className="blog-post-title">Very Happy Meal</h1>
        <PreviewPage 
          selectedVoiceAudio={audioSrc}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          showProgress={true}
          showDuration={true}
        />
        <div className="blog-post-text">
          <h2>What's going here?</h2>
          <p>McDonald's reported golden third-quarter results on Monday, and they may be worth some lovin'.</p>
          <h2>What does this mean?</h2>
          <p>Investors have been keeping McDonald's at arm's length, wary that the US's new obsession with weight-loss drugs won't exactly jibe with Happy Meals. But it seems the fast food giant kept its "Supersize Me" spirit: by beefing up prices, McDonald's managed to make 9% more in same-store sales.</p>
        </div>
      </div>
    </div>
  );
};

export default LiveDemo;
