import React, { useState, useEffect } from 'react';
import { SketchPicker } from 'react-color';
import Switch from '@mui/material/Switch';
import moneyIcon from '../../assets/icons/knife.svg';

const DesignComponent = ({ onDesignChange, onVoiceChange, onPaymentRequirement, onSignificantChange, initialState, customStyles, moneyIcon }) => {
  const [themeColor, setThemeColor] = useState(initialState.themeColor || '#FF6900');
  const [isDarkMode, setIsDarkMode] = useState(initialState.isDarkMode !== undefined ? initialState.isDarkMode : true);
  const [showCompanyTag, setShowCompanyTag] = useState(initialState.showCompanyTag !== undefined ? initialState.showCompanyTag : true);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState(initialState.selectedVoice || 'Alloy');
  const [isHDVoice, setIsHDVoice] = useState(initialState.isHDVoice || false);
  const [voiceSpeed, setVoiceSpeed] = useState(initialState.voiceSpeed || 1.0);
  const [uploadedAudio, setUploadedAudio] = useState(initialState.uploadedAudio || null);
  const [price, setPrice] = useState(0);

  useEffect(() => {
    setThemeColor(customStyles?.theme?.backgroundColor || initialState.themeColor || '#FF6900');
    setIsDarkMode(customStyles?.audioPlayer?.background === '#282828' || initialState.isDarkMode !== undefined ? initialState.isDarkMode : true);
    setShowCompanyTag(customStyles?.showCompanyTag !== undefined ? customStyles.showCompanyTag : (initialState.showCompanyTag !== undefined ? initialState.showCompanyTag : true));
    setSelectedVoice(customStyles?.selectedVoice || initialState.selectedVoice || 'Alloy');
    setIsHDVoice(customStyles?.isHDVoice || initialState.isHDVoice || false);
    setVoiceSpeed(customStyles?.voiceSpeed || initialState.voiceSpeed || 1.0);
  }, [customStyles, initialState]);

  const handleColorChange = (color) => {
    setThemeColor(color.hex);
    onDesignChange({
      theme: { backgroundColor: color.hex },
      playPauseButton: {
        color: color.hex,
        background: isDarkMode ? '#282828' : 'white'
      },
      progressBar: { backgroundColor: color.hex },
    });
    updatePaymentRequirement();
  };

  const toggleDarkMode = () => {
    const newIsDarkMode = !isDarkMode;
    setIsDarkMode(newIsDarkMode);
    onDesignChange({
      isDarkMode: newIsDarkMode,
      audioPlayer: { background: newIsDarkMode ? '#282828' : 'white' },
      playPauseButton: { color: themeColor, background: newIsDarkMode ? '#282828' : 'white' },
      time: { color: newIsDarkMode ? 'white' : 'black' },
      progressContainer: { background: newIsDarkMode ? '#ffffff29' : '#0000001f' },
    });
  };

  const toggleCompanyTag = () => {
    const newShowCompanyTag = !showCompanyTag;
    setShowCompanyTag(newShowCompanyTag);
    onDesignChange({ showCompanyTag: newShowCompanyTag });
    updatePaymentRequirement();
  };

  const handleVoiceChange = (event) => {
    const newVoice = event.target.value;
    setSelectedVoice(newVoice);
    onVoiceChange(newVoice);
    onDesignChange({ selectedVoice: newVoice });
    onSignificantChange();
  };

  const toggleHDVoice = () => {
    setIsHDVoice(!isHDVoice);
    onDesignChange({ isHDVoice: !isHDVoice });
    updatePaymentRequirement();
  };

  const handleSpeedChange = (event) => {
    const speed = parseFloat(event.target.value);
    setVoiceSpeed(speed);
    onDesignChange({ voiceSpeed: speed });
    onSignificantChange();
  };

  const updatePaymentRequirement = () => {
    let priceId = null;
    let currentPrice = 0;

    if (isHDVoice) {
      priceId = 'price_1Q6EzfCEY8Ej5Z6H3kHjDyqL';
      currentPrice = 0.50;
    }

    if (!showCompanyTag) {
      const noTagPriceId = 'price_1OwoqMCEY8Ej5Z6HlVy2EwSv';
      const noTagPrice = 1.47;
      if (noTagPrice > currentPrice) {
        priceId = noTagPriceId;
        currentPrice = noTagPrice;
      }
    }

    setPrice(currentPrice);
    onPaymentRequirement(!!priceId, priceId, currentPrice);
  };

  const handleClick = () => setDisplayColorPicker(!displayColorPicker);
  const handleClose = () => setDisplayColorPicker(false);

  return (
    <div className="design-component">
      <h2>Customize Player</h2>
      <div className="design-option">
        <label>Voice Option:</label>
        <select className="voice-options" value={selectedVoice} onChange={handleVoiceChange}>
          {['Alloy', 'Echo', 'Fable', 'Onyx', 'Nova', 'Shimmer'].map(voice => (
            <option key={voice} value={voice}>{voice}</option>
          ))}
        </select>
      </div>
      <div className="design-option">
        <label>
          HD Voice
          <img src={moneyIcon} alt="Paid feature" className="money-icon" />
        </label>
        <Switch checked={isHDVoice} onChange={toggleHDVoice} />
      </div>
      <div className="design-option">
        <label>Voice Speed:</label>
        <input
          type="range"
          min="0.25"
          max="4.0"
          step="0.25"
          value={voiceSpeed}
          onChange={handleSpeedChange}
        />
        <span>{voiceSpeed}x</span>
      </div>
      <div className="design-option">
        <label>Theme Color:</label>
        <div className="color-boxes">
          {['#FF0000', '#00FF00', '#0000FF'].map(color => (
            <div key={color} className="color-box" style={{ backgroundColor: color }} onClick={() => handleColorChange({ hex: color })} />
          ))}
        </div>
        <button className="color-picker-button" onClick={handleClick}>Choose Color</button>
        {displayColorPicker && (
          <div className="popover">
            <div className="cover" onClick={handleClose}></div>
            <SketchPicker color={themeColor} onChangeComplete={handleColorChange} />
          </div>
        )}
      </div>
      <div className="design-option">
        <label>Dark Mode</label>
        <Switch checked={isDarkMode} onChange={toggleDarkMode} />
      </div>
      <div className="design-option">
        <label>
          Show Company Tag
          <img src={moneyIcon} alt="Paid feature" className="money-icon" />
        </label>
        <Switch checked={showCompanyTag} onChange={toggleCompanyTag} />
      </div>
    </div>
  );
};

export default DesignComponent;
