import React, { useContext, useState, useEffect } from 'react';
import { SketchPicker } from 'react-color';
import { Switch } from '@mui/material';
import { AudioPlayerContext } from '../AudioPlayerContext';
import AudioPlayer from '../AudioPlayer';
import { auth, firestore } from '../../firebase';
import { doc, setDoc } from 'firebase/firestore';

function Design({ onSaveDesign, canEdit, customStyles, voiceSettings, hasChanges, setHasChanges, onTestAudio, blogContent }) {
  const { updateSettings } = useContext(AudioPlayerContext);
  const [isDarkMode, setIsDarkMode] = useState(customStyles?.audioPlayer?.background === '#282828' || false);
  const [themeColor, setThemeColor] = useState(customStyles?.playPauseButton?.color || '#FFD600');
  const [showCompanyTag, setShowCompanyTag] = useState(customStyles?.showCompanyTag ?? true);
  const [speechModel, setSpeechModel] = useState(voiceSettings?.model || 'tts-1');
  const [speechSpeed, setSpeechSpeed] = useState(voiceSettings?.speed || 1.0);
  const [isGeneratingAudio, setIsGeneratingAudio] = useState(false);
  const [saveStatus, setSaveStatus] = useState('');
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (customStyles) {
      const newIsDarkMode = customStyles.audioPlayer?.background === '#282828';
      setIsDarkMode(newIsDarkMode);
      console.log('Setting dark mode:', newIsDarkMode);
      setThemeColor(customStyles.playPauseButton?.color || '#FFD600');
      setShowCompanyTag(customStyles.showCompanyTag ?? true);
    } else {
      // Set default values if customStyles is undefined
      setIsDarkMode(true);
      setThemeColor('#FFD600');
      setShowCompanyTag(true);
    }
    if (voiceSettings) {
      setSpeechModel(voiceSettings.model || 'tts-1');
      setSpeechSpeed(voiceSettings.speed || 1.0);
    }
  }, [customStyles, voiceSettings]);

  const updateCustomStyles = (newStyles) => {
    console.log('Updating custom styles:', newStyles);
    updateSettings({ ...customStyles, ...newStyles }, voiceSettings);
    setHasChanges(true);
  };

  const updateVoiceSettings = (newSettings) => {
    console.log('Updating voice settings:', newSettings);
    updateSettings(customStyles, { ...voiceSettings, ...newSettings });
    setHasChanges(true);
  };

  const handleToggleDarkMode = (event) => {
    const newIsDarkMode = event.target.checked;
    console.log('Toggling dark mode:', newIsDarkMode);
    setIsDarkMode(newIsDarkMode);
    updateCustomStyles({
      audioPlayer: { background: newIsDarkMode ? '#282828' : 'white' },
      playPauseButton: { background: newIsDarkMode ? '#282828' : 'white' },
      time: { color: newIsDarkMode ? 'white' : 'black' },
    });
  };

  const handleColorChange = (color) => {
    setThemeColor(color.hex);
    updateCustomStyles({
      playPauseButton: { color: color.hex },
      progressBar: { backgroundColor: color.hex },
      theme: { backgroundColor: color.hex },
    });
  };

  const handleCompanyTagToggle = (event) => {
    if (canEdit) {
      setShowCompanyTag(event.target.checked);
      updateCustomStyles({ showCompanyTag: event.target.checked });
    }
  };

  const handleVoiceChange = (event) => {
    updateVoiceSettings({ name: event.target.value });
  };

  const handleModelChange = (event) => {
    setSpeechModel(event.target.value);
    updateVoiceSettings({ model: event.target.value });
  };

  const handleSpeedChange = (event) => {
    const speed = parseFloat(event.target.value);
    setSpeechSpeed(speed);
    updateVoiceSettings({ speed });
  };

  const generateTestAudio = async () => {
    setIsGeneratingAudio(true);
    try {
      const response = await fetch('https://us-central1-butter-reader.cloudfunctions.net/generateTestAudio', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          voice: voiceSettings.name,
          model: speechModel,
          speed: speechSpeed,
          blogContent: blogContent
        })
      });
      const data = await response.json();
      if (data.url) {
        onTestAudio(data.url);
      }
    } catch (error) {
      console.error('Error generating test audio:', error);
    } finally {
      setIsGeneratingAudio(false);
    }
  };

  const saveDesign = async () => {
    const userId = auth.currentUser.uid;
    try {
      const userDocRef = doc(firestore, `customers/${userId}/styles/current`);
      await setDoc(userDocRef, {
        customStyles,
        voiceSettings
      }, { merge: true });
      console.log('Design saved successfully');
      setSaveStatus('Design saved!');
      setHasChanges(false);
    } catch (error) {
      console.error('Error saving design:', error);
      setSaveStatus('Error saving design');
    }
  };

  const handleClick = () => setDisplayColorPicker(true);
  const handleClose = () => setDisplayColorPicker(false);

  // Render component (similar to before, but using the new state and handlers)
  return (
    <div className="design-component">
      <h2>Customize Your Widget</h2>
      <div className="questions">
        <div className="question">
          <label>Voice Option:</label>
          <select className="voice-options" value={voiceSettings.name} onChange={handleVoiceChange}>
            {['Alloy', 'Echo', 'Fable', 'Onyx', 'Nova', 'Shimmer'].map(voice => (
              <option key={voice} value={voice}>{voice}</option>
            ))}
          </select>
        </div>
        <div className="question">
          <label>Select Color:</label>
          <div className="color-boxes">
            {['#FF0000', '#00FF00', '#0000FF'].map(color => (
              <div key={color} className="color-box" style={{ backgroundColor: color }} onClick={() => handleColorChange({ hex: color })} />
            ))}
            <button className="color-picker-button" onClick={handleClick}>Choose Color</button>
            {displayColorPicker && (
              <div className="popover">
                <div className="cover" onClick={handleClose} />
                <SketchPicker color={themeColor} onChange={handleColorChange} />
              </div>
            )}
          </div>
        </div>
        <div className="question">
        <label>Dark Mode:</label>
        <Switch 
          checked={isDarkMode} 
          onChange={handleToggleDarkMode} 
          sx={{
            '& .MuiSwitch-switchBase.Mui-checked': {
              color: '#FFD600',
            },
            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
              backgroundColor: '#FFD600',
            },
          }}
        />
      </div>
      <div className="question">
        <label>Show Logo:</label>
        <Switch 
          checked={showCompanyTag} 
          onChange={handleCompanyTagToggle} 
          disabled={!canEdit} 
            sx={{
              '& .MuiSwitch-switchBase.Mui-checked': {
                color: '#FFD600',
              },
              '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                backgroundColor: '#FFD600',
              },
            }}
          />
        </div>
        <div className="question">
          <label>Speech Model:</label>
          <select value={speechModel} onChange={handleModelChange}>
            <option value="tts-1">Standard Quality</option>
            <option value="tts-1-hd">High Quality</option>
          </select>
        </div>
        <div className="question">
          <label>Speech Speed:</label>
          <input
            type="range"
            min="0.25"
            max="4.0"
            step="0.25"
            value={speechSpeed}
            onChange={handleSpeedChange}
          />
          <span>{speechSpeed}x</span>
        </div>
        <div className="question-buttons">
          <button className="test-button" onClick={generateTestAudio} disabled={isGeneratingAudio}>
            {isGeneratingAudio ? 'Generating...' : 'Test Audio'}
          </button>
          {hasChanges && <button className="save-button" onClick={saveDesign}>Save Design</button>}
        </div>
        {saveStatus && <div className="save-status">{saveStatus}</div>}
      </div>
    </div>
  );
}

export default Design;
