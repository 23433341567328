// PreviewPage.js
import React, { useContext } from 'react';
import AudioPlayer from '../AudioPlayer'; // Adjust the import path as necessary
import { AudioPlayerContext } from '../AudioPlayerContext'; // Adjust the import path as necessary

const PreviewPage = ({ 
  audioSrc, 
  isPlaying, 
  setIsPlaying, 
  showProgress = true, 
  showDuration = true 
}) => {
  const { customStyles, voiceSettings } = useContext(AudioPlayerContext);

  return (
    <div className="preview-page">
      <div className="live-view">
        <AudioPlayer 
          audioSrc={audioSrc || voiceSettings.audioSrc} 
          customStyles={customStyles}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          showProgress={showProgress}
          showDuration={showDuration}
        />
      </div>
    </div>
  );
};

export default PreviewPage;
