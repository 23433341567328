import React, { useState } from 'react';
import PriceTableModal from './priceTable';
import './UpgradePlanPrompt.css';

const UpgradePlanPrompt = ({ currentPlan, requiredPlan }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="upgrade-prompt-container">
      <h2>Unlock Premium Features</h2>
      <p>Upgrade to our {requiredPlan} plan to access advanced features and take your audio content to the next level!</p>
      
      <div className="feature-list">
        <h3>With the {requiredPlan} plan, you can:</h3>
        <ul>
          <li>Attach audio widgets directly to your blog posts</li>
          <li>Convert content from your site into audio automatically</li>
          <li>Access advanced customization options for your audio player</li>
          <li>Increase your monthly audio generation limit</li>
        </ul>
      </div>

      <div className="pricing-info">
        <p>Upgrade now for just <span className="price">$3/month</span> and transform your content strategy!</p>
      </div>

      <button className="upgrade-button" onClick={() => setIsModalOpen(true)}>View Pricing Plans</button>
      
      <PriceTableModal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        userPlan={currentPlan}
        onPaymentSuccess={() => {/* Handle payment success */}}
      />
    </div>
  );
};

export default UpgradePlanPrompt;