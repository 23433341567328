import React, { useContext } from 'react';
import LiveDemo from './demo/LiveDemo';
import { AudioPlayerContext } from '../AudioPlayerContext';

const ControlPanel = ({ blogContent, isPlaying, setIsPlaying, audioSrc }) => {
  const { voiceSettings } = useContext(AudioPlayerContext);

  return (
    <div className="mcdonalds-page">
      <div className="backdrop"></div>
      <div className="content-container">
        <LiveDemo 
          blogContent={blogContent}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          audioSrc={audioSrc}
        />
      </div>
    </div>
  );
};

export default ControlPanel;
