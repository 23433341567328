import React, { useState } from 'react';
import Logo from '../../assets/butterreader-logo.svg';
import NavIcon from '../../assets/icons/nav.svg';
import SmallLogo from '../../assets/small.png'; // Assuming this is the correct path
import './Header.css';

const Header = () => {
  const [isNavVisible, setIsNavVisible] = useState(false);
  const pages = ['How it Works', 'Pricing', 'Blog', 'Community'];

  const handleLoginClick = () => {
    window.location.href = "https://app.butterreader.blog";
  };

  return (
    <header className="header-demo">
      <div className="header__logo">
        <a href="https://www.butterreader.blog/">
          <picture>
            <source media="(max-width: 768px)" srcSet={SmallLogo}/>
            <img src={Logo} alt="ButterReader Logo" />
          </picture>
        </a>
      </div>
      <button className={`nav-toggle ${isNavVisible ? 'toggled' : ''}`} onClick={() => setIsNavVisible(!isNavVisible)} aria-label="toggle navigation">
        <img src={NavIcon} alt="Navigation Menu" />
      </button>
      <nav className={`header__nav ${isNavVisible ? 'nav-visible' : ''}`}>
        <div className="nav__items">
          {pages.map(page => (
            <a key={page} href={`https://www.butterreader.blog/${page.toLowerCase().replace(/\s+/g, '-')}`} className="nav__link">
              {page}
            </a>
          ))}
        </div>
        <div className="header__actions">
          <a href="https://app.butterreader.blog/demo" className="action__link">Demo</a>
          <button className="login__button" onClick={handleLoginClick}>Login</button>
        </div>
      </nav>
    </header>
  );
};

export default Header;
