import React from 'react';
import './Stats.css'; // Make sure this path is correct
import butterImage from '../../assets/butter.png'; // Update the path to your butter.png image

const Banner = () => {
    return (
        <div className="banner-container">
            <h1 className="banner-text">
                Getting readers through your content smooth like..
            </h1>
            <img src={butterImage} alt="Butter" className="butter-image" />
        </div>
    );
};

export default Banner;
