import React, { useState, useEffect } from 'react';
import { collection, addDoc, doc, getDoc, setDoc, getDocs, deleteDoc, updateDoc } from 'firebase/firestore';
import { firestore, auth } from '../firebase';
import DesignComponent from '../components/website/DesignComponent';
import StandaloneAudioPlayer from '../components/website/StandaloneAudioPlayer';
import { serverTimestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './Generator.css';
import { useUserPlan } from '../App';
import PriceTableModal from '../components/priceTable';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const Generator = () => {
  const userPlan = useUserPlan();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [text, setText] = useState('');
  const [embedCode, setEmbedCode] = useState('');
  const [audioGenerated, setAudioGenerated] = useState(false);
  const [audioKey, setAudioKey] = useState(0);
  const [audioUrl, setAudioUrl] = useState('');
  const [selectedVoice, setSelectedVoice] = useState('Alloy');
  const [paymentRequired, setPaymentRequired] = useState(false);
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);
  const [docId, setDocId] = useState('');
  const [stripePriceId, setStripePriceId] = useState('');
  const [charLimit, setCharLimit] = useState(500);
  const [customStyles, setCustomStyles] = useState({
    container: {},
    player: {},
    showCompanyTag: true,
  });
  const [generatedHistory, setGeneratedHistory] = useState([]);
  const [selectedHistoryItem, setSelectedHistoryItem] = useState(null);
  const [uploadedAudio, setUploadedAudio] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [designComponentState, setDesignComponentState] = useState({
    themeColor: '#FF6900',
    isDarkMode: true,
    showCompanyTag: true,
    selectedVoice: 'Alloy',
    isHDVoice: false,
    voiceSpeed: 1.0
  });

  const defaultStyles = {
    container: {},
    player: {},
    showCompanyTag: true,
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const docIdFromQuery = params.get('docId');
    const paymentSuccess = params.get('paymentSuccess');
    
    const savedState = localStorage.getItem('demoState');
    const parsedState = savedState ? JSON.parse(savedState) : null;
    const docIdFromStorage = parsedState ? parsedState.docId : null;

    const docIdToUse = docIdFromQuery || docIdFromStorage;

    const fetchDemoAudio = async () => {
      if (docIdToUse) {
        const demoDocRef = doc(firestore, 'demo', docIdToUse);
        const demoDocSnap = await getDoc(demoDocRef);
        if (demoDocSnap.exists()) {
          const demoData = demoDocSnap.data();
          setAudioUrl(demoData.audioUrl);
          setCustomStyles(demoData.customStyles || defaultStyles);
          setText(demoData.text || '');
          setSelectedVoice(demoData.voice || 'Alloy');
          setPaymentRequired(demoData.paymentRequired || false);
          setStripePriceId(demoData.stripePriceId || '');
          setAudioGenerated(true);
          setDocId(docIdToUse);
          setDesignComponentState({
            themeColor: demoData.customStyles?.theme?.backgroundColor || '#FF6900',
            isDarkMode: demoData.customStyles?.audioPlayer?.background === '#282828',
            showCompanyTag: demoData.showCompanyTag,
            selectedVoice: demoData.voice || 'Alloy',
            isHDVoice: demoData.isHDVoice || false,
            voiceSpeed: demoData.voiceSpeed || 1.0
          });

          const userId = auth.currentUser?.uid;
          if (userId && (paymentSuccess === 'true' || !demoData.paymentRequired)) {
            // Generate embed code
            const newEmbedCode = generateEmbedCode(demoData.audioUrl, docIdToUse);
            setEmbedCode(newEmbedCode);

            // Update the demo document with the embed code
            await updateDoc(demoDocRef, { embedCode: newEmbedCode });

            // Move to generated subcollection
            await setDoc(doc(firestore, `customers/${userId}/generated`, docIdToUse), {
              ...demoData,
              paid: true,
              embedCode: newEmbedCode
            });
            await deleteDoc(demoDocRef);
            setPaymentSuccessful(true);
          } else if (demoData.paymentRequired) {
            setPaymentSuccessful(false);
          }
        }
      } else if (parsedState) {
        // If no docId, but we have saved state, use that
        setAudioUrl(parsedState.audioUrl || '');
        setCustomStyles(parsedState.customStyles || defaultStyles);
        setText(parsedState.text || '');
        setSelectedVoice(parsedState.selectedVoice || 'Alloy');
        setPaymentRequired(parsedState.paymentRequired || false);
        setStripePriceId(parsedState.stripePriceId || '');
        setAudioGenerated(parsedState.audioGenerated || false);
        setDocId(parsedState.docId || '');
        setDesignComponentState(parsedState.designComponentState || {
          themeColor: '#FF6900',
          isDarkMode: true,
          showCompanyTag: true,
          selectedVoice: 'Alloy',
          isHDVoice: false,
          voiceSpeed: 1.0
        });
        if (parsedState.uploadedAudio) {
          setUploadedAudio(new File([new Blob()], parsedState.uploadedAudio.name, {
            type: parsedState.uploadedAudio.type,
            lastModified: parsedState.uploadedAudio.lastModified
          }));
        }
        if (parsedState.embedCode) {
          setEmbedCode(parsedState.embedCode);
        }
      }
      fetchGeneratedHistory();
    };

    fetchDemoAudio();

    // Don't clear localStorage here, we'll do it after successful processing
    // localStorage.removeItem('demoState');

    // Don't clear URL parameters here, we'll do it after successful processing
    // window.history.replaceState({}, document.title, window.location.pathname);
  }, []);

  useEffect(() => {
    const savedState = localStorage.getItem('demoState');
    if (savedState) {
      const parsedState = JSON.parse(savedState);
      if (parsedState.embedCode && parsedState.embedCode !== embedCode) {
        setEmbedCode(parsedState.embedCode);
        console.log('Embed Code:', parsedState.embedCode);
      }
    }
  }, []);

  useEffect(() => {
    if (docId) {
      console.log("docId updated:", docId);
    }
  }, [docId]);

  const handleTextChange = (e) => {
    const newText = e.target.value;
    if (newText.length <= charLimit) {
      setText(newText);
      if (newText.trim().length === 0) {
        setUploadedAudio(null);
      }
      resetAudioGenerationState();
    }
  };

  const handlePaymentRequirement = (requiresPayment, priceId) => {
    setPaymentRequired(requiresPayment);
    setStripePriceId(priceId);
    console.log("Payment required:", requiresPayment, "Price ID:", priceId);
  };

  const handleGenerateAudio = async () => {
    if (!text.trim() && !uploadedAudio) {
      alert("Please enter some text or upload an audio file before generating.");
      return;
    }
    try {
      let audioUrl;
      let priceId = null;

      if (uploadedAudio) {
        audioUrl = await uploadAudioFile(uploadedAudio);
        priceId = 'price_1Q6EzfCEY8Ej5Z6H3kHjDyqL'; // Price for uploaded audio
      } else {
        const response = await fetch('https://us-central1-butter-reader.cloudfunctions.net/synthesizeDemoSpeech', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            text,
            voice: selectedVoice.toLowerCase(),
            speed: designComponentState.voiceSpeed,
            isHDVoice: designComponentState.isHDVoice
          })
        });

        if (response.ok) {
          const data = await response.json();
          audioUrl = data.url;
        } else {
          throw new Error("Failed to generate audio");
        }
      }

      setAudioUrl(audioUrl);
      setAudioKey(prevKey => prevKey + 1);
      setAudioGenerated(true);

      // Determine if payment is required
      if (designComponentState.isHDVoice) {
        priceId = 'price_1Q6EzfCEY8Ej5Z6H3kHjDyqL';
      } else if (!customStyles.showCompanyTag) {
        priceId = 'price_1OwoqMCEY8Ej5Z6HlVy2EwSv';
      }

      setPaymentRequired(!!priceId);
      setStripePriceId(priceId);

      const userId = auth.currentUser ? auth.currentUser.uid : null;

      // Save in demo collection
      const docRef = await addDoc(collection(firestore, "demo"), {
        audioUrl: audioUrl,
        customStyles,
        showCompanyTag: customStyles.showCompanyTag,
        createdAt: serverTimestamp(),
        paid: !paymentRequired,
        userId: userId,
        text: text,
        voice: selectedVoice,
        isUploadedAudio: !!uploadedAudio,
        isHDVoice: designComponentState.isHDVoice,
        speed: designComponentState.voiceSpeed,
        paymentRequired: !!priceId,
        stripePriceId: priceId
      });

      setDocId(docRef.id);

      // Generate embed code after we have the docRef.id
      const newEmbedCode = generateEmbedCode(audioUrl, docRef.id);
      setEmbedCode(newEmbedCode);

      // Update the document with the embed code
      await updateDoc(docRef, { embedCode: newEmbedCode });

      if (!priceId) {
        setPaymentSuccessful(true);
        if (userId) {
          await setDoc(doc(firestore, "customers", userId, "generated", docRef.id), {
            audioUrl: audioUrl,
            customStyles,
            showCompanyTag: customStyles.showCompanyTag,
            createdAt: serverTimestamp(),
            paid: true,
            embedCode: newEmbedCode,
            text: text,
            voice: selectedVoice,
            isUploadedAudio: !!uploadedAudio,
            isHDVoice: designComponentState.isHDVoice,
            speed: designComponentState.voiceSpeed
          });
        }
      } else {
        setPaymentSuccessful(false);
      }
    } catch (error) {
      console.error('Error generating audio:', error);
      alert('An error occurred while generating the audio. Please try again.');
    }
  };

  const handlePayment = async () => {
    setIsLoading(true);
    setError(null);
    console.log("Attempting payment. stripePriceId:", stripePriceId, "docId:", docId);
    if (!stripePriceId || !docId) {
      console.error("Missing stripePriceId or docId");
      setError("Missing payment information. Please try generating the audio again.");
      setIsLoading(false);
      return;
    }

    const userId = auth.currentUser ? auth.currentUser.uid : null;

    try {
      const response = await fetch('https://us-central1-butter-reader.cloudfunctions.net/createCheckoutSession', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          stripePriceId,
          docId,
          userId,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        window.location.href = data.url;
      } else {
        const errorText = await response.text();
        throw new Error(errorText);
      }
    } catch (error) {
      console.error("Error during payment process:", error);
      setError("An error occurred during the payment process. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignificantChange = () => {
    setAudioGenerated(false);
    setEmbedCode('');
    setAudioUrl('');
    setDocId('');
    setPaymentSuccessful(false);
  };

  const generateEmbedCode = (audioSrc, docId) => {
    return `<div id="demo-widget-container" data-config-id="${docId}"></div>
<script src="https://app.butterreader.blog//widget/DemoWidget.bundle.js"></script>`;
  };

  const fetchDocumentAndGenerateEmbedCode = async (docId) => {
    const userId = auth.currentUser?.uid;
    if (!userId) {
      console.error("User not authenticated");
      return;
    }

    const docRef = doc(firestore, `customers/${userId}/generated`, docId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      if (data.paid) {
        setAudioUrl(data.audioUrl);
        setCustomStyles(data.customStyles || defaultStyles);
        const newEmbedCode = generateEmbedCode(data.audioUrl, docId);
        setEmbedCode(newEmbedCode);
        setPaymentSuccessful(true);
        setAudioGenerated(true);
        setText(data.text || '');
        setSelectedVoice(data.voice || 'Alloy');
      } else {
        console.error("This audio is not paid for and cannot be accessed.");
      }
    } else {
      console.error("Document not found");
    }
  };

  const handleDesignChange = (newStyles) => {
    setCustomStyles(prevStyles => {
      const updatedStyles = { ...prevStyles, ...newStyles };
      setDesignComponentState(prevState => ({
        ...prevState,
        themeColor: updatedStyles.theme?.backgroundColor || prevState.themeColor,
        isDarkMode: updatedStyles.isDarkMode !== undefined ? updatedStyles.isDarkMode : prevState.isDarkMode,
        showCompanyTag: updatedStyles.showCompanyTag !== undefined ? updatedStyles.showCompanyTag : prevState.showCompanyTag,
        isHDVoice: updatedStyles.isHDVoice !== undefined ? updatedStyles.isHDVoice : prevState.isHDVoice,
        voiceSpeed: updatedStyles.voiceSpeed || prevState.voiceSpeed
      }));
      return updatedStyles;
    });
  };

  const fetchGeneratedHistory = async () => {
    const userId = auth.currentUser?.uid;
    if (userId) {
      const generatedRef = collection(firestore, "customers", userId, "generated");
      const querySnapshot = await getDocs(generatedRef);
      const history = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setGeneratedHistory(history);
    }
  };

  const GeneratedHistoryItem = ({ item }) => (
    <div className="generated-history-item">
      <StandaloneAudioPlayer audioSrc={item.audioUrl} customStyles={item.customStyles} showCompanyTag={item.showCompanyTag} />
      <button onClick={() => navigator.clipboard.writeText(item.embedCode)}>Copy Embed Code</button>
    </div>
  );

  const handleFileUpload = (file) => {
    if (file && file.type.startsWith('audio/') && file.size <= 5 * 1024 * 1024) {
      setUploadedAudio(file);
      setText('');
      resetAudioGenerationState();
    } else {
      alert('Please upload an audio file smaller than 5MB.');
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    handleFileUpload(file);
  };

  const uploadAudioFile = async (file) => {
    const storage = getStorage();
    const fileName = `demoAudio/${Date.now()}_${file.name}`;
    const storageRef = ref(storage, fileName);
    
    try {
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      return downloadURL;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  };

  const CheckoutButton = ({ stripePriceId, docId }) => {
    const handleCheckout = async () => {
      const response = await fetch('/api/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ priceId: stripePriceId, docId }),
      });

      const session = await response.json();
      window.location = session.url;
    };

    return (
      <button onClick={handleCheckout}>Proceed to Checkout</button>
    );
  };

  const resetAudioGenerationState = () => {
    setAudioGenerated(false);
    setEmbedCode('');
    setDocId('');
    setPaymentRequired(false);
    setStripePriceId('');
  };

  return (
    <div className="demo-page">
      <div className="left-panel">
        <h1>Generate Audio Widget</h1>
        <p>Input your text or upload an audio file to customize your audio widget.</p>
        <div className="input-options">
        {audioGenerated && (
          <div className="embed-code-div">
            <h3>Your Custom Widget is Ready!</h3>
            {paymentSuccessful ? (
              <>
                <p>Here's your embed code:</p>
                <textarea readOnly value={embedCode} />
              </>
            ) : paymentRequired ? (
              <>
                <p>To get your embed code, please complete the payment.</p>
                <button onClick={handlePayment} disabled={isLoading}>
                  {isLoading ? 'Processing...' : 'Proceed to Checkout'}
                </button>
              </>
            ) : (
              <>
                <p>Here's your embed code:</p>
                <textarea readOnly value={embedCode} />
              </>
            )}
          </div>
        )}
          {!uploadedAudio && (
            <div className="text-input-area">
              <h3>Option 1: Text-to-Speech</h3>
              <p>Enter your text below to convert it to speech (up to {charLimit} characters):</p>
              <div className="textarea-container">
                <textarea 
                  value={text} 
                  onChange={handleTextChange} 
                  maxLength={charLimit} 
                  placeholder="Enter text here..." 
                />
                <div className="char-count">
                  {text.length}/{charLimit}
                </div>
              </div>
            </div>
          )}
          
          {!text.trim() && (
            <div className="audio-upload-area">
              <h3>Option 2: Upload Your Audio</h3>
              <p>Drag and drop your audio file here (max 5MB):</p>
              <div 
                className={`drop-zone ${isDragging ? 'dragging' : ''} ${uploadedAudio ? 'has-file' : ''}`}
                onDragEnter={handleDragEnter}
                onDragOver={(e) => e.preventDefault()}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                onClick={() => document.getElementById('file-input').click()}
              >
                {uploadedAudio ? (
                  <p>File uploaded: {uploadedAudio.name}</p>
                ) : (
                  <p>Drag your audio file here or click to upload</p>
                )}
                <input 
                  id="file-input"
                  type="file" 
                  accept="audio/*" 
                  onChange={(e) => handleFileUpload(e.target.files[0])} 
                  style={{display: 'none'}}
                />
              </div>
              {uploadedAudio && (
                <button className="remove-audio-button" onClick={() => setUploadedAudio(null)}>Remove Audio</button>
              )}
            </div>
          )}
        </div>
        
        {(text.trim().length > 0 || uploadedAudio) && !audioGenerated && (
          <button className='generate-button' onClick={handleGenerateAudio} disabled={!selectedVoice && !uploadedAudio}>
            Generate Audio Widget
          </button>
        )}
        
        {generatedHistory.length > 0 && (
          <div className="generated-history">
            <h3>Previously Generated Audio</h3>
            <select 
              className="generated-history-dropdown"
              onChange={(e) => setSelectedHistoryItem(generatedHistory[e.target.value])}
              value={selectedHistoryItem ? generatedHistory.indexOf(selectedHistoryItem) : ''}
            >
              <option value="">Select Free/Paid Generations</option>
              {generatedHistory.map((item, index) => (
                <option key={item.id} value={index}>
                  {item.text ? `${item.text.substring(0, 30)}...` : 'Untitled'} ({new Date(item.createdAt?.seconds * 1000 || Date.now()).toLocaleString()})
                </option>
              ))}
            </select>
            {selectedHistoryItem && <GeneratedHistoryItem item={selectedHistoryItem} />}
          </div>
        )}
        {uploadedAudio && (
          <div className="uploaded-audio-info">
            <p>Uploaded audio: {uploadedAudio.name}</p>
            <button className="remove-audio-button" onClick={() => setUploadedAudio(null)}>Remove Audio</button>
          </div>
        )}
      </div>
      <div className="right-side-demo">
        <StandaloneAudioPlayer key={audioKey} audioSrc={audioUrl} customStyles={customStyles} showCompanyTag={customStyles.showCompanyTag} />
        <DesignComponent
          onDesignChange={handleDesignChange}
          onVoiceChange={setSelectedVoice}
          onPaymentRequirement={handlePaymentRequirement}
          onSignificantChange={handleSignificantChange}
          initialState={designComponentState}
          customStyles={customStyles}
        />
      </div>
    </div>
  );
};

export default Generator;
