import React, { useState, useEffect } from 'react';
import './Stats.css';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { getUserPlanAndUsage } from '../planService';

const CurrentPlan = ({ onUpgradeClick }) => {
    const [planDetails, setPlanDetails] = useState({ planName: 'Loading...', audioDownloadLimit: 10, audioSessionLimit: 0 });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getUserPlanAndUsage();
                console.log('Fetched Plan Name:', data.planName); // Debugging

                if (data.planName === 'God Level') {
                    setPlanDetails({
                        planName: 'God Level',
                        audioDownloadLimit: 'Unlimited',
                        audioSessionLimit: 'Unlimited'
                    });
                } else {
                    setPlanDetails({
                        ...data,
                        planName: data.planName,
                        audioDownloadLimit: data.audioDownloadLimit || 0,
                        audioSessionLimit: data.audioSessionLimit || 0
                    });
                }
            } catch (error) {
                console.error("Error fetching plan details:", error);
                setPlanDetails({ planName: 'Free Tier', audioDownloadLimit: 10, audioSessionLimit: 0 });
            }
        };

        fetchData();
    }, []);

    const radius = 45;
    const circumference = 2 * Math.PI * radius;
    let strokeDashoffset = circumference;
    if (planDetails.audioDownloadLimit !== 'Unlimited') {
        strokeDashoffset = ((1 - planDetails.audioSessionLimit / planDetails.audioDownloadLimit) * circumference).toFixed(2);
    }

    return (
        <div className="current-plan-container">
            <div className="plan-header">
                <h2 className='plan-title'>{planDetails.planName}</h2>
            </div>
            <div className="plan-details">
                <div>
                    <h3 className='label-limit'>Audio monthly limit</h3>
                    <p className='amount'>({planDetails.audioSessionLimit} reached)</p>
                </div>
                <div className="progress-section">
                    <svg width="120" height="120" className="progress-svg">
                        <circle
                            className="progress-ring__background"
                            stroke="#ccc"
                            strokeWidth="6"
                            fill="transparent"
                            r={radius}
                            cx="60"
                            cy="60" />
                        <circle
                            className="progress-ring__circle"
                            stroke="#FFD600"
                            strokeWidth="6"
                            strokeDasharray={circumference}
                            strokeDashoffset={strokeDashoffset}
                            strokeLinecap="round"
                            fill="transparent"
                            r={radius}
                            cx="60"
                            cy="60"
                            transform="rotate(-90 60 60)" />
                    </svg>
                    <span className="visitors-count">{planDetails.audioSessionLimit} / {planDetails.audioDownloadLimit}</span>
                    <div className="info-icon-container">
                        <InfoIcon className="info-icon" />
                        <span className="tooltip">You have {typeof planDetails.audioDownloadLimit === 'number' ? (planDetails.audioDownloadLimit - planDetails.audioSessionLimit) : 'Unlimited'} remaining</span>
                    </div>
                </div>
            </div>
            <button onClick={onUpgradeClick} className="upgrade-link">Upgrade Plan</button>
        </div>
    );
};

export default CurrentPlan;
