import React, { useRef, useEffect, useState } from 'react';
import './AudioPlayer.css';
import { doc, setDoc } from 'firebase/firestore';
import { firestore, auth } from '../firebase';

function AudioPlayer({ 
  audioSrc, 
  customStyles = {}, 
  isPlaying, 
  setIsPlaying, 
  showProgress = true, 
  showDuration = true 
}) {
  const audioRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    const audio = audioRef.current;
    
    const handleLoadedMetadata = () => {
      setDuration(audio.duration);
    };

    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);
    };

    const handleEnded = () => {
      setIsPlaying(false);
      setCurrentTime(0);
      recordPlayDuration(audio.duration);
    };

    audio.addEventListener('loadedmetadata', handleLoadedMetadata);
    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('ended', handleEnded);

    return () => {
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      audio.removeEventListener('timeupdate', handleTimeUpdate);
      audio.removeEventListener('ended', handleEnded);
    };
  }, [setIsPlaying]);

  useEffect(() => {
    const audio = audioRef.current;
    if (isPlaying) {
      audio.play().catch(e => console.error("Playback failed:", e));
    } else {
      audio.pause();
    }
  }, [isPlaying]);

  const handleProgressClick = (e) => {
    const audio = audioRef.current;
    const boundingRect = e.target.getBoundingClientRect();
    const clickedPosition = e.clientX - boundingRect.left;
    const newTime = (clickedPosition / boundingRect.width) * duration;
    audio.currentTime = newTime;
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const recordPlayDuration = async (duration) => {
    const userId = auth.currentUser.uid;
    const playDurationRef = doc(firestore, 'customers', userId, 'playDurations', new Date().toISOString());
    await setDoc(playDurationRef, { duration });
  };

  return (
    <div className="audio-player" style={customStyles.audioPlayer}>
      <audio ref={audioRef} src={audioSrc} preload="metadata" />
      <div className="audio-player__theme" style={customStyles.theme}>
        <button className="audio-player__play-pause-btn" onClick={() => setIsPlaying(!isPlaying)} style={customStyles.playPauseButton}>
          {isPlaying ? '||' : '►'}
        </button>
      </div>
      {showProgress && (
        <div className="audio-player__progress-container" onClick={handleProgressClick} style={customStyles.progressContainer}>
          <div className="audio-player__progress-bar" style={{ ...customStyles.progressBar, width: `${(currentTime / duration) * 100}%` }}></div>
        </div>
      )}
      {showDuration && (
        <div className="audio-player__time" style={customStyles.time}>{formatTime(currentTime)}</div>
      )}
    </div>
  );
}

export default AudioPlayer;
