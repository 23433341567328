import React, { useEffect, useState } from 'react';
import './priceTable.css';
import { useUserPlan } from '../App';
import { getCheckoutUrl } from './stripePayment';
import { firestore } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';

const PriceTableModal = ({ isOpen, onClose, onPaymentSuccess }) => {
  const rawUserPlan = useUserPlan(); 
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    if (isOpen) {
      const fetchPlans = async () => {
        const plansCollectionRef = collection(firestore, 'plans');
        const plansSnapshot = await getDocs(plansCollectionRef);
        const plansData = plansSnapshot.docs
          .map(doc => {
            const planData = { id: doc.id, ...doc.data() };
            console.log('Fetched plan:', planData); // Debugging log
            return planData;
          })
          .filter(plan => plan.name !== 'God Level') // Filter out the 'God Level' plan
          .sort((a, b) => a.rank - b.rank); // Sort by rank
        setPlans(plansData);
      };
  
      fetchPlans().catch(console.error);
    }
  }, [isOpen]);
   

  const userPlanRank = plans.find(plan => plan.name.toLowerCase() === rawUserPlan.toLowerCase())?.rank || 0;

  const handleCheckout = async (priceId) => {
    try {
      const checkoutUrl = await getCheckoutUrl(priceId);
      localStorage.setItem('paymentInitiated', 'true'); // Set flag here
      window.location.href = checkoutUrl;
    } catch (error) {
      console.error('Error initiating checkout:', error);
    }
  };  

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-container" onClick={(e) => e.stopPropagation()}>
        <h1>We got the butter, show us the bread!</h1>
        <div className="price-table">
          {plans.map((plan, index) => (
            <div key={index} className={`price-card ${plan.name.toLowerCase() === rawUserPlan.toLowerCase() ? 'selected' : ''}`}>
              <div className="plan-name">{plan.name}</div>
              <div className="plan-price">{`$${plan.priceMonthly}/m`}</div>
              <ul>
                <li>{`${plan.audioDownloadLimit} Downloads`}</li>
                <li>{`${plan.audioSessionLimit} Sessions`}</li>
                {plan.widgetEditing && <li>Widget Editing</li>}
              </ul>
              {/* Only show the 'Purchase Now' button for plans higher than the user's current plan */}
              {plan.rank > userPlanRank && (
                <button onClick={() => handleCheckout(plan.priceId)}>Purchase Now</button>
              )}
            </div>
          ))}
        </div>
        <button className="close-button" onClick={onClose}>X</button>
      </div>
    </div>
  );
};

export default PriceTableModal;
