// src/components/TopBar.js
import React from 'react';
import { useTranslation } from 'react-i18next';

function TopBar() {
  const { t } = useTranslation();
  const [selectedPreset, setSelectedPreset] = React.useState('default');

  const handlePresetChange = (event) => {
    setSelectedPreset(event.target.value);
  };

  return (
    <div className="top-bar">
      <span className="welcome-text">{t('welcomeToPlayground')}</span>
    </div>
  );
}

export default TopBar;
