import React from 'react';
import './SuccessPopup.css'; // Make sure to create a corresponding CSS file

const SuccessPopup = ({ isOpen, onClose, message }) => {
  if (!isOpen) return null;

  return (
    <div className="success-popup-overlay">
      <div className="success-popup">
        <h2>Success!</h2>
        <p>{message}</p>
        <button className="login-button" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default SuccessPopup;
